<template>
    <div class="admin-competition full-height full-width">
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-hidden full-height full-width p-relative">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <div class="p-absolute top zindex-10 full-width">
                    <v-container
                        fluid
                        class="pa-6 page-header"
                        :class="{ scrolled: scrolled || $vuetify.breakpoint.xsOnly }"
                    >
                        <v-row>
                            <v-col class="d-flex align-center justify-space-between" cols="12">
                                <h2>Edit Competition</h2>

                                <div class="action-buttons">
                                    <v-btn
                                        class="light--text ml-3"
                                        :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="red"
                                        small
                                        @click="deleteComp"
                                    >
                                        <v-icon color="light" small>mdi-close</v-icon>
                                        <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly && deleteCount == 0"
                                            >DELETE</span
                                        >
                                        <span class="ml-2" v-if="deleteCount == 1">ARE YOU SURE?</span>
                                        <span class="ml-2" v-if="deleteCount == 2">ALL DATA WILL BE DELETED?</span>
                                        <span class="ml-2" v-if="deleteCount == 3">CONFIRM?</span>
                                    </v-btn>
                                    <v-btn
                                        class="light--text ml-3"
                                        :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="dark"
                                        small
                                        :to="{
                                            name: 'admin.competition-reports',
                                            params: { id: this.$route.params.id },
                                        }"
                                    >
                                        <v-icon color="light" small>mdi-table</v-icon>
                                        <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">Reports</span>
                                    </v-btn>
                                    <!-- <v-btn
                                        class="light--text ml-3"
                                        :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="blue"
                                        small
                                        @click="createCode"
                                    >
                                        <v-icon color="light" small>mdi-sale</v-icon>
                                        <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">Coupon Code</span>
                                    </v-btn> -->
                                    <v-btn
                                        class="light--text ml-3"
                                        :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="blue"
                                        small
                                        :to="{
                                            name: 'admin.competition.create',
                                            query: { id: this.$route.params.id },
                                        }"
                                    >
                                        <v-icon color="light" small>mdi-pencil</v-icon>
                                        <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">Edit</span>
                                    </v-btn>

                                    <template v-if="isLive">
                                        <v-btn
                                            class="light--text ml-3"
                                            :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                            :icon="$vuetify.breakpoint.xsOnly"
                                            color="dark"
                                            small
                                            :to="{
                                                name: 'admin.competition-rooms',
                                                params: { id: this.$route.params.id },
                                            }"
                                        >
                                            <v-icon color="light" small>mdi-monitor-account</v-icon>
                                            <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">Rooms</span>
                                        </v-btn>
                                    </template>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="grey"
                                        small
                                        @click="close"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="dark" small>mdi-close</v-icon>
                                        </template>

                                        <template v-else>Close</template>
                                    </v-btn>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ success: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="success"
                                        :disabled="!valid"
                                        :loading="saving"
                                        small
                                        @click="save"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="white" small>mdi-check</v-icon>
                                        </template>

                                        <template v-else>Save</template>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="overflow-y-scroll full-height full-width">
                    <div class="spacer"></div>
                    <v-container class="mb-6" style="max-width: 768px">
                        <v-row dense>
                            <v-col cols="12">
                                <p class="primary--text small--text">Competition Title</p>
                                <v-text-field
                                    ref="email"
                                    v-model="competition.title"
                                    type="text"
                                    class="field"
                                    :disabled="loading"
                                    dense
                                    filled
                                    solo
                                    flat
                                    placeholder="Please enter title..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <template v-if="isOnline">
                            <v-row dense>
                                <v-col cols="12">
                                    <p class="primary--text small--text">Judging</p>

                                    <category-judge-selector
                                        v-model="categoryJudges"
                                        :competition="competition"
                                    ></category-judge-selector>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <p class="primary--text small--text">Feedback</p>

                                    <category-judge-selector
                                        v-model="feedbackJudges"
                                        :competition="competition"
                                    ></category-judge-selector>
                                </v-col>
                            </v-row>
                        </template>
                    </v-container>
                </div>
            </template>
        </div>

        <v-snackbar v-model="snackbar" timeout="3000" :color="snackbarColor" top>
            <span class="full-width text-center d-block">{{ snackbarText }}</span>
        </v-snackbar>
    </div>
</template>

<script>
// Services
import Competition from "@/modules/app-module/competition";
import Discount from "@/modules/app-module/discount";
import CategoryJudgeSelector from "@/components/form-field/category-judge-selector.vue";

// Packages
import _ from "lodash";

export default {
    name: "admin-competition",

    meta: {
        title: "Competition",
    },

    components: {
        CategoryJudgeSelector,
    },

    data() {
        return {
            deleteCount: 0,
            error: false,
            scrolled: false,
            errorMessage: "",
            loading: false,
            saving: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            competition: {},
            categoryJudges: [],
            feedbackJudges: [],
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        valid() {
            let noCategory = this.categoryJudges.filter((item) => !item?.category?._id);
            let noJudges = this.categoryJudges.filter((item) => !item?.judges?.length);

            if (noCategory.length || noJudges.length) return false;

            let noFeedbackCategory = this.feedbackJudges.filter((item) => !item?.category?._id);
            let noFeedbackJudges = this.feedbackJudges.filter((item) => !item?.judges?.length);

            if (noFeedbackCategory.length || noFeedbackJudges.length) return false;

            return true;
        },
        isOnline() {
            return this.competition?.data?.judgingType == "online";
        },
        isLive() {
            return this.competition?.data?.judgingType == "live";
        },
    },

    methods: {
        async createCode() {
            const list = "FREETEST";
            const codes = list.split(", ");
            console.log(codes);
            const specifiedStart = new Date(2023, 4, 16); // specify date as YYYY, MM-1, DD
            const specifiedEnd = new Date(2023, 7, 1); // specify date as YYYY, MM-1, DD
            const startDate = new Date(specifiedStart);

            const endDate = new Date(specifiedEnd);
            const amount = 0;
            const percentage = false;
            const maxUsage = 1;
            const freeEntry = true;
            for (const code in codes) {
                const payload = {
                    title: `FREE ENTRY ${this.competition.title} MaxUsage = ${maxUsage} `,
                    realms: [this.$app.liveRealm],
                    data: {
                        freeEntry: freeEntry,
                        code: codes[code],
                        discount: amount,
                        percentage: percentage,
                        usageCount: 0,
                        maxUsage: maxUsage,
                        startDate: startDate,
                        endDate: endDate,
                        competition: [this.competition._id],
                    },
                };
                const response = await Discount.create(payload).then(({ data }) => data);
                console.log("CREATED", response);
            }
        },
        async deleteComp() {
            if (this.deleteCount >= 3) {
                const response = await Competition.delete(this.$route.params.id).then(({ data }) => data);
                console.log("COMP DELETED", response);
            }
            this.deleteCount++;
        },
        image(entry) {
            return entry?.data?.image;
        },
        async init() {
            this.loading = true;

            try {
                this.competition = await Competition.get(this.$route.params.id).then(({ data }) => data);

                if (this.competition?.data?.categoryJudges?.length) {
                    this.categoryJudges = this.competition.data.categoryJudges;
                }

                if (this.competition?.data?.feedbackJudges?.length) {
                    this.feedbackJudges = this.competition.data.feedbackJudges;
                }
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.loading = false;
        },
        close() {
            this.$router.push({ name: "admin.competitions" });
        },
        async save() {
            this.saving = true;

            let competition = this.competition;
            let categoryJudges = this.categoryJudges;
            let feedbackJudges = this.feedbackJudges;

            try {
                const payload = {
                    title: competition.title,
                    data: {
                        categoryJudges: categoryJudges.map((item) => {
                            return {
                                category: item?.category || {},
                                judges: item?.judges?.map(({ _id }) => _id) || [],
                            };
                        }),
                        feedbackJudges: feedbackJudges.map((item) => {
                            return {
                                category: item?.category || {},
                                judges: item?.judges?.map(({ _id }) => _id) || [],
                            };
                        }),
                    },
                };

                let updated = await Competition.update(this.$route.params.id, payload).then(({ data }) => data);
                console.log("UPDATED", updated);
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.saving = false;
        },
        handleScroll(e) {
            let scrollTop = e.target.scrollTop;

            if (scrollTop > 30) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
    },

    async mounted() {
        await this.init();

        this.$nextTick(() => {
            const scrollContainer = document.querySelector(".overflow-y-scroll");
            scrollContainer.addEventListener("scroll", this.handleScroll);
        });
    },

    destroyed() {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) scrollContainer.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="scss"></style>
