<template>
    <div class="category-judge-selector">
        <v-card class="rounded-lg light mb-6 overflow-hidden" v-for="(category, index) in items" :key="index">
            <div class="grey px-3 py-2 d-flex align-center justify-space-between">
                <b>Category</b>

                <v-btn icon small @click="removeCategory(index)">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <v-row>
                    <v-col cols="12" sm="6">
                        <template v-if="category.category">
                            <div class="rounded-lg pa-2 group-item d-flex align-center justify-space-between">
                                <b class="small--text">{{ category.category.title }}</b>

                                <v-btn icon x-small @click="category.category = null">
                                    <v-icon x-small>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-else>
                            <v-btn elevation="0" color="default" block @click="selectCategory(index)">Add category</v-btn>
                        </template>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <template v-if="category.judges.length">
                            <div class="rounded-lg group-item d-flex flex-column mb-3">
                                <div class="pa-2 d-flex align-center full-width justify-space-between" :class="{ 'border-bottom': index != category.judges.length - 1 }" v-for="(judge, index) in category.judges" :key="judge._id">
                                    <b class="small--text">{{ judge.title }}</b>

                                    <v-btn icon x-small @click="removeJudge(category, index)">
                                        <v-icon x-small>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </template>

                        <!-- <template v-else> -->
                        <v-btn elevation="0" color="default" block @click="selectJudges(category, index)">Add judges</v-btn>
                        <!-- </template> -->
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <v-btn color="primary" @click="addCategory()" v-if="!allSelected">Add a category</v-btn>

        <content-list-dialog v-model="selected" ref="contentDialog" title="Select Judges" type="persona" :headers="headers" @confirm="confirmJudgeSelect"></content-list-dialog>
        <category-select-dialog ref="categoryDialog" :selectable="selectableCategories" :selected="selectedCategories" @category="confirmCategorySelect"></category-select-dialog>
    </div>
</template>

<script>
import CategorySelectDialog from "@/components/dialogs/category-select-dialog.vue";
import ContentListDialog from "@/components/dialogs/content-list-dialog.vue";

export default {
    name: "category-judge-selector",

    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        competition: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    components: {
        CategorySelectDialog,
        ContentListDialog,
    },

    data() {
        return {
            items: this.value,
            headers: [
                {
                    text: "Name",
                    value: "title",
                    type: "text-cell",
                },
                {
                    text: "Email Address",
                    value: "username",
                    type: "text-cell",
                },
            ],
            selected: [],
        };
    },

    computed: {
        selectableCategories() {
            return this.competition?.data?.categories || [];
        },
        selectedCategories() {
            return this.items.map(({ category }) => category);
        },
        allSelected() {
            return this.items.length >= this.selectableCategories.length;
        },
    },

    methods: {
        addCategory() {
            this.items.push({
                category: null,
                judges: [],
            });
        },
        removeCategory(index) {
            this.items.splice(index, 1);
        },
        selectCategory(index) {
            this.$refs.categoryDialog.open(index);
        },
        confirmCategorySelect(category, index) {
            this.items[index].category = category;
            this.$refs.categoryDialog.close();
        },
        selectJudges(category, index) {
            this.selected = category.judges;
            this.$refs.contentDialog.open(index);
        },
        confirmJudgeSelect(judges, index) {
            this.items[index].judges = judges;
            this.$refs.contentDialog.close();
            this.selected = [];
        },
        removeJudge(category, index) {
            category.judges.splice(index, 1);
        },
    },

    async mounted() {},
};
</script>

<style lang="scss"></style>
