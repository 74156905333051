<template>
    <v-dialog v-model="display" content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="category-select-dialog no-selection">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid class="background">
                <v-row>
                    <v-col cols="12" sm="4" v-for="item in filtered" :key="item._id">
                        <basic-tile :item="item" @click="select(item)" />
                    </v-col>
                </v-row>
            </v-container>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Components
import BasicTile from "@/components/global/basic-tile.vue";

// Packages
import _ from "lodash";

export default {
    name: "category-select-dialog",

    props: {
        title: {
            type: String,
            default() {
                return "Select Category";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        selectable: {
            type: Array,
            default() {
                return [];
            },
        },
        selected: {
            type: Array,
            default() {
                return [];
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    components: {
        BasicTile,
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            index: null,
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
        };
    },

    computed: {
        filtered() {
            return this.selectable.filter((item) => !this.selected.some((selected) => selected?._id == item._id));
        },
    },

    methods: {
        hasImage(competition) {
            let image = competition?.data?.image;

            return image?._id || image;
        },
        init(index) {
            this.showError = false;
            this.loading = false;

            this.index = index;
        },
        open(index) {
            this.init(index);
            this.display = true;
        },
        select(model) {
            this.loading = true;
            this.$emit("category", model, this.index);
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        close() {
            this.display = false;
            this.index = null;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.category-select-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
