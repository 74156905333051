import FluroContent from "./fluro-content";

class Discount extends FluroContent {
    constructor() {
        super("discount");
    }
}

const _Discount = new Discount();
export default _Discount;
